import { useEffect } from 'react';
import PropTypes from 'prop-types';
import AlertComponent from './AlertComponent';

export default function OverlayComponent(props) {

    useEffect(() => {
        window.scroll({top: 0});
        $('body').css({'overflow':'hidden'});
    }, []);

    function dismiss() {
        props.handleDismiss(Object.assign({}, props, { type: 'overlay' }));
        $('body').css({'overflow':'auto'});
    }

    const { alerts } = props;

    return (
        <div className="sb-alert-overlay" role="alertdialog" aria-label="Alerts" aria-describedby="sb_overlay_message">
            <div className={"sb-alert-overlay-box"}>
                <div id="sb_overlay_message" className="sb-box-message" tabIndex="1" role="document">
                    {
                        alerts.map((alert) =>
                            <AlertComponent key={alert.id} message={alert.message} />
                        )
                    }
                </div>
                <div className="sb-box-action">
                    <button ref={el => el && el.focus()} onClick={dismiss}>Continue</button>
                </div>
            </div>
        </div>
    );
}

OverlayComponent.propTypes = {
    alerts: PropTypes.array.isRequired,
    handleDismiss: PropTypes.func,
};
